(function () {
  'use strict';

  /* @ngdoc object
   * @name settings.users
   * @description
   *
   */
  angular
    .module('settings.users', [
      'ui.router',
      'users.list',
      'users.edit'
    ]);
}());
